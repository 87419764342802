export const QualificationList = [
  "12th Passed Arts",
  "12th Passed Commerce",
  "12th Passed Science",
  "Graduation in Arts",
  "Graduation in Commerce",
  "Graduation in Science",
  "Graduation in Management",
  "Graduation in IT or Computer Science",
  "Master Degree - Science",
  "Master Degree - Arts",
  "Master Degree -Commerce",
  "MBA",
  "MSW",
  "MCA",
  "Other Master Degree or Equivalent",
  "PhD",
];
