export const OrderBookDisabled = () => {
  return (
    <div className="text-black text-center space-y-5 h-[70vh] flex flex-col justify-center p-3">
      <div>
        <span className="text-2xl font-bold text-[#BF0808] lg:text-4xl">
          You can place book order using your Credentials in the Exam Portal
        </span>
      </div>
      <div className="bg-sky-500 hover:bg-sky-400 px-3 py-2 h-9 flex justify-center items-center transition-colors w-fit rounded-md mx-auto text-white">
          <a href="https://exam.nhwm.co.in/">Go to Exam Portal</a>
        </div>
      <div className="text-center font-bold text-xl max-w-screen-lg mx-auto">
        If you have any query, please contact us at{" "}
        <a href="tel:03617968083">03617968083</a> from 11:00 AM to 02:00 PM,
        Monday to Friday except the holidays.
        <br />
        OR <br /> Email to us -{" "}
        <a href="mailto:nhwm.oh@gmail.com">nhwm.oh@gmail.com</a>
      </div>
    </div>
  );
};
