/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useRef, useState } from "react";
import { StateList } from "../../fixtures/states";
import { QualificationList } from "../../fixtures/qualification";
import { JobApplicationFee } from "../../fixtures/post";
import { GetUploadUrl, onApplyJob } from "../../redux/api/job.api";
import axios from "axios";

const qrCodeUrl =
  "https://nhwm-job-portal.s3.ap-south-1.amazonaws.com/payment/payment_qr.jpg";

export const ApplyJob = () => {
  const availablePosts = ["RWO", "ITWO", "HRWO", "DWO", "AWO"];

  const fileRef = useRef<HTMLInputElement>(null);

  const [submitError, setSubmitError] = useState(false);

  const [post, setPost] = useState("RWO");
  const [postError, setPostError] = useState(false);

  const [state, setState] = useState("Andhra Pradesh");
  const [stateError, setStateError] = useState(false);

  const [district, setDistrict] = useState("");
  const [districtError, setDistrictError] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState(false);

  const [middleName, setMiddleName] = useState("");
  const [middleNameError, setMiddleNameError] = useState(false);

  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState(false);

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);

  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState(false);

  const [whatsApp, setWhatsApp] = useState("");
  const [whatsAppError, setWhatsAppError] = useState(false);

  const [fatherName, setFatherName] = useState("");
  const [fatherNameError, setFatherNameError] = useState(false);

  const [motherName, setMotherName] = useState("");
  const [motherNameError, setMotherNameError] = useState(false);

  const [dateOfBirth, setDateOfBirth] = useState("");
  const [dateOfBirthError, setDateOfBirthError] = useState(false);

  const [gender, setGender] = useState("Male");
  const [genderError, setGenderError] = useState(false);

  const [category, setCategory] = useState("General");
  const [categoryError, setCategoryError] = useState(false);

  const [nationality, setNationality] = useState("Indian");
  const [nationalityError, setNationalityError] = useState(false);

  const [maritalStatus, setMaritalStatus] = useState("Single");
  const [maritalStatusError, setMaritalStatusError] = useState(false);

  const [address, setAddress] = useState("");
  const [addressError, setAddressError] = useState(false);

  const [pinCode, setPinCode] = useState("");
  const [pinCodeError, setPinCodeError] = useState(false);

  const [highestQualificationPercent, setHighestQualificationPercent] =
    useState("");
  const [
    highestQualificationPercentError,
    setHighestQualificationPercentError,
  ] = useState(false);

  const [highestQualification, setHighestQualification] = useState(
    "Graduate in Science"
  );
  const [highestQualificationError, setHighestQualificationError] =
    useState(false);

  const [twelveStream, setTwelveStream] = useState("Science");
  const [twelveStreamError, setTwelveStreamError] = useState(false);

  const [technicalEducation, setTechnicalEducation] = useState("");
  const [technicalEducationError, setTechnicalEducationError] = useState(false);

  const [experience, setExperience] = useState("");
  const expierenceMaxLength = 500;
  const [experienceError, setExperienceError] = useState(false);

  const [txnNumber, setTxnNumber] = useState("");
  const [txnNumberError, setTxnNumberError] = useState(false);

  const [paymentUrl, setPaymentUrl] = useState("");
  const [paymentUrlError, setPaymentUrlError] = useState(false);
  const [paymentKey, setPaymentKey] = useState("");

  const [aadharCardFrontUrl, setAadharCardFrontUrl] = useState("");
  const [aadharCardFrontUrlError, setAadharCardFrontUrlError] = useState(false);
  const [aadharCardFrontUrlKey, setAadharCardFrontUrlKey] = useState("");

  const [aadharCardBackUrl, setAadharCardBackUrl] = useState("");
  const [aadharCardBackUrlError, setAadharCardBackUrlError] = useState(false);
  const [aadharCardBackUrlKey, setAadharCardBackUrlKey] = useState("");

  const [passportUrl, setPassportUrl] = useState("");
  const [passportUrlError, setPassportUrlError] = useState(false);
  const [passportUrlKey, setPassportUrlKey] = useState("");

  const [signatureUrl, setSignatureUrl] = useState("");
  const [signatureUrlError, setSignatureUrlError] = useState(false);
  const [signatureUrlKey, setSignatureUrlKey] = useState("");

  const [handWritingUrl, setHandWritingUrl] = useState("");
  const [handWritingUrlError, setHandWritingUrlError] = useState(false);
  const [handWritingUrlKey, setHandWritingUrlKey] = useState("");

  const [twelveMarksheet, setTwelveMarksheet] = useState("");
  const [twelveMarksheetError, setTwelveMarksheetError] = useState(false);
  const [twelveMarksheetKey, setTwelveMarksheetKey] = useState("");

  const [tenthMarksheet, setTenthMarksheet] = useState("");
  const [tenthMarksheetError, setTenthMarksheetError] = useState(false);
  const [tenthMarksheetKey, setTenthMarksheetKey] = useState("");

  const [highestMarksheet, setHighestMarksheet] = useState("");
  const [highestMarksheetError, setHighestMarksheetError] = useState(false);
  const [highestMarksheetKey, setHighestMarksheetKey] = useState("");

  const [tenthPercentage, setTenthPercentage] = useState("");
  const [tenthPercentageError, setTenthPercentageError] = useState(false);

  const [twelvePercentage, setTwelvePercentage] = useState("");
  const [twelvePercentageError, setTwelvePercentageError] = useState(false);
  const [hrwoOption, setHrwoOption] = useState("");
  const [hrwoOptionError, setHrwoOptionError] = useState(false);

  const [headOfficePrompt, setHeadOfficePrompt] = useState<
    undefined | string
  >();

  const [selectedIdx, setSelectedIdx] = useState<number | null>(null);

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  const [isFormDirty] = useState(true);

  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const isValidEmail = (email: string): boolean => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

    return emailRegex.test(email);
  };

  const [termsAgreed, setTermsAgreed] = useState(false);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (isFormDirty) {
        const confirmationMessage =
          "All unsaved changes will be lost. Are you sure you want to leave?";
        event.returnValue = confirmationMessage;
        return confirmationMessage;
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isFormDirty]);

  useEffect(() => {
    if (post.toUpperCase() === "ITWO" || post.toUpperCase() === "HRWO") {
      setHeadOfficePrompt(`I am Applying for Head Office from `);
    } else {
      setHeadOfficePrompt(undefined);
    }
  }, [post]);

  const handleCheckboxChange = (index: number) => {
    setSelectedIdx(index === selectedIdx ? null : index);
  };
  const checkFileValidity = async (
    file: File | undefined
  ): Promise<boolean> => {
    if (!file) {
      alert("No file selected.");
      return false;
    }

    const validImageTypes = ["image/jpeg", "image/png"];
    const validImageExtensions = ["jpg", "jpeg", "png"];

    const fileExtension = file.name.split(".").pop()?.toLowerCase();

    if (
      validImageTypes.includes(file.type) ||
      (fileExtension && validImageExtensions.includes(fileExtension))
    ) {
      if (file.size > 1024 * 1024) {
        alert(
          `Image size exceeds the limit. Please select an image with a size up to 1 MB.`
        );
        return false;
      }

      const img = new Image();
      img.src = URL.createObjectURL(file);

      return new Promise<boolean>((resolve) => {
        img.onload = () => {
          resolve(true);
        };
      });
    } else {
      alert(`Please select a valid image (JPG, JPEG or PNG).`);
      return false;
    }

    return true;
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    setSignedUrl: React.Dispatch<React.SetStateAction<string>>,
    setSignedKey: React.Dispatch<React.SetStateAction<string>>
  ) => {
    const fileInput = event.target;
    const file = fileInput.files?.[0];

    if (await checkFileValidity(file)) {
      try {
        const signedUrlResponse = await GetUploadUrl(file!.name);
        if (signedUrlResponse) {
          const { data } = signedUrlResponse;
          const { url, Key: fileName } = data;

          if (fileName) {
            setSignedUrl(url);
            setSignedKey(fileName);

            await uploadFileToSignedUrl(file!, url);
          }
        }
      } catch (err) {
        console.error(err);
      }
    } else {
      if (fileInput) {
        fileInput.value = "";
      }
    }
  };

  const uploadFileToSignedUrl = async (file: File, signedUrl: string) => {
    try {
      const response = await axios.put(signedUrl, file);

      if (response.status === 200) {
      } else {
        console.error(
          "File upload failed. Server returned:",
          response.statusText
        );
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const listOfDistrict = () => {
    const selectedState = StateList.filter((item) => item.state === state);
    if (Array.isArray(selectedState) && selectedState.length > 0) {
      return selectedState[0].districts;
    }
    return [];
  };

  const handleEmailChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    setEmailFunc: React.Dispatch<React.SetStateAction<string>>,
    setErrorFunc: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    const newEmail = e.target.value;
    setEmailFunc(newEmail);

    setErrorFunc(!isValidEmail(newEmail));
  };

  const jobInfo = () => {
    return (
      <div className="border-b border-gray-900/10 pb-12">
        <div className="flex flex-col items-center w-full mx-auto text-center gap-1">
          <span className="md:hidden text-blue-700 font-bold text-2xl md:text-3xl lg:text-4xl">
            Online Application Form for <br /> Basic Recruitment Test
          </span>
          <span className="hidden md:block text-blue-700 font-bold text-2xl md:text-3xl lg:text-4xl">
            Online Application Form for Basic Recruitment Test
          </span>
          <span className="text-red-700 font-bold text-xs md:text-base">
            Before Submitting your Form, read & understand properly the
            Instructions and the Job details.
          </span>
        </div>

        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div className="sm:col-span-4">
            <label
              htmlFor="post-info"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              <b>Name of the Post I want to Apply-</b>
            </label>
            <div className="mt-2">
              <select
                id="post"
                name="post"
                autoComplete="post-name"
                className="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                value={post}
                onChange={(e) => {
                  setHeadOfficePrompt(undefined);
                  setPost(e.target.value);
                }}
              >
                <option disabled selected>
                  Select one
                </option>
                {availablePosts.map((post) => (
                  <option>{post}</option>
                ))}
              </select>
            </div>
          </div>
          {/* HRWO */}
          {post === "HRWO" && (
            <div className="sm:col-span-4 flex flex-col">
              {[
                "Option A (Human Resource)",
                "Option B (Sales & Marketing)",
                "Option C (Operations Management)",
                "Option D (Leadership Management)",
              ].map((option, index) => (
                <div key={index}>
                  <label className="cursor-pointer flex items-center p-1">
                    <input
                      type="radio"
                      name="hrwo-1"
                      className="radio checked:bg-blue-500 mr-4"
                      onChange={() => setHrwoOption(option)}
                    />
                    <span className="label-text">{option}</span>
                  </label>
                </div>
              ))}
              {getErrorText(hrwoOptionError)}
            </div>
          )}

          <div className="sm:col-span-2 sm:col-start-1">
            <label
              htmlFor="city"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              {headOfficePrompt}State -
            </label>
            <div className="mt-2">
              <select
                id="state"
                name="state"
                autoComplete="state-name"
                className="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                value={state}
                onChange={(e) => {
                  setState(e.target.value);
                }}
              >
                <option disabled selected>
                  Select one
                </option>
                {StateList.map((item) => (
                  <option key={item.state} value={item.state}>
                    {item.state}
                  </option>
                ))}
              </select>
              {/* <p style={{ width: 280 }} className="text-sm font-bold mt-4">
                {headOfficePrompt}
              </p> */}
            </div>
          </div>
          {(post.toUpperCase() === "DWO" || post.toUpperCase() === "AWO") && (
            <div className="sm:col-span-2">
              <label
                htmlFor="region"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                District
              </label>
              <div className="mt-2">
                <select
                  id="state"
                  name="state"
                  autoComplete="state-name"
                  className="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                  value={district}
                  onChange={(e) => setDistrict(e.target.value)}
                >
                  <option disabled selected>
                    Select one
                  </option>
                  {listOfDistrict().map((title) => (
                    <option>{title}</option>
                  ))}
                </select>
                {getErrorText(districtError)}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    setState: React.Dispatch<React.SetStateAction<string>>,
    setError: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    setState(e.target.value);
    if (!e.target.value.trim()) {
      setError(true);
    } else {
      setError(false);
    }
  };

  const getErrorText = (error: boolean) => {
    return error ? (
      <span className="text-red-500 text-xs">This is a required field</span>
    ) : (
      <></>
    );
  };

  const getClassName = (error: boolean) => {
    return `block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm placeholder:text-gray-400 outline outline-1 sm:text-sm sm:leading-6 ${
      error ? "outline-red-500" : "outline-gray-300 focus:outline-blue-500"
    }`;
  };

  const personalInfo = () => {
    return (
      <div className="border-b border-gray-900/10 pb-12">
        <h2 className="text-base font-semibold leading-7 text-gray-900">
          Personal Information
        </h2>

        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div className="sm:col-span-2">
            <label
              htmlFor="first-name"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              First name
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="first-name"
                id="first-name"
                value={firstName}
                autoComplete="first-name"
                onChange={(e) =>
                  handleChange(e, setFirstName, setFirstNameError)
                }
                className={getClassName(firstNameError)}
              />
              {getErrorText(firstNameError)}
            </div>
          </div>

          <div className="sm:col-span-2">
            <label
              htmlFor="middle-name"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Middle Name
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="middle-name"
                value={middleName}
                id="middle-name"
                autoComplete="middle-name"
                onChange={(e) => setMiddleName(e.target.value)}
                className={getClassName(middleNameError)}
              />
              {getErrorText(middleNameError)}
            </div>
          </div>

          <div className="sm:col-span-2">
            <label
              htmlFor="last-name"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Last name
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="last-name"
                value={lastName}
                id="last-name"
                autoComplete="last-name"
                onChange={(e) => handleChange(e, setLastName, setLastNameError)}
                className={getClassName(lastNameError)}
              />
              {getErrorText(lastNameError)}
            </div>
          </div>

          <div className="sm:col-span-4">
            <label
              htmlFor="email"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Email address
            </label>
            <div className="mt-2">
              <input
                id="email"
                name="email"
                value={email}
                type="email"
                autoComplete="email"
                onChange={(e) => handleEmailChange(e, setEmail, setEmailError)}
                className={getClassName(emailError)}
              />
              {emailError && (
                <span className="text-red-500 text-xs">
                  Please enter a valid email!
                </span>
              )}
            </div>
          </div>

          <div className="sm:col-span-3">
            <label
              htmlFor="phone"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Phone Number
            </label>
            <div className="mt-2">
              <input
                id="phone"
                name="phone"
                value={phone}
                type="text"
                onChange={(e) => handleChange(e, setPhone, setPhoneError)}
                className={getClassName(phoneError)}
              />
              {getErrorText(phoneError)}
            </div>
          </div>

          <div className="sm:col-span-3">
            <label
              htmlFor="whatsapp"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              WhatsApp Number
            </label>
            <div className="mt-2">
              <input
                id="whatsapp"
                value={whatsApp}
                name="whatsapp"
                type="text"
                onChange={(e) => handleChange(e, setWhatsApp, setWhatsAppError)}
                className={getClassName(whatsAppError)}
              />
              {getErrorText(whatsAppError)}
            </div>
          </div>

          <div className="sm:col-span-3">
            <label
              htmlFor="father-name"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Father's name
            </label>
            <div className="mt-2">
              <input
                type="text"
                value={fatherName}
                name="father-name"
                id="father-name"
                autoComplete="father-name"
                onChange={(e) =>
                  handleChange(e, setFatherName, setFatherNameError)
                }
                className={getClassName(fatherNameError)}
              />
              {getErrorText(fatherNameError)}
            </div>
          </div>
          <div className="sm:col-span-3">
            <label
              htmlFor="mother-name"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Mother's name
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="mother-name"
                value={motherName}
                id="mother-name"
                autoComplete="mother-name"
                onChange={(e) =>
                  handleChange(e, setMotherName, setMotherNameError)
                }
                className={getClassName(motherNameError)}
              />
              {getErrorText(motherNameError)}
            </div>
          </div>
          <div className="sm:col-span-2">
            <label
              htmlFor="dob"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Date of Birth
            </label>
            <div className="mt-2">
              <input
                type="date"
                name="dob"
                value={dateOfBirth}
                id="dob"
                autoComplete="dob"
                onChange={(e) =>
                  handleChange(e, setDateOfBirth, setDateOfBirthError)
                }
                className={getClassName(dateOfBirthError)}
              />
              {getErrorText(dateOfBirthError)}
            </div>
          </div>
          <div className="sm:col-span-2">
            <label
              htmlFor="gender"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Gender
            </label>
            <div className="mt-2">
              <select
                id="gender"
                name="gender"
                autoComplete="gender-name"
                onChange={(e) => setGender(e.target.value)}
                className="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
              >
                <option>Male</option>
                <option>Female</option>
                <option>Other</option>
              </select>
              {getErrorText(genderError)}
            </div>
          </div>

          <div className="sm:col-span-2">
            <label
              htmlFor="cast"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Category
            </label>
            <div className="mt-2">
              <select
                id="cast"
                name="cast"
                autoComplete="cast-name"
                onChange={(e) => setCategory(e.target.value)}
                className="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
              >
                <option>General</option>
                <option>ST</option>
                <option>SC</option>
                <option>OBC</option>
                <option>HST</option>
                <option>PH</option>
              </select>
              {getErrorText(categoryError)}
            </div>
          </div>

          <div className="sm:col-span-2">
            <label
              htmlFor="nationality"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Nationality
            </label>
            <div className="mt-2">
              <select
                id="nationality"
                name="nationality"
                autoComplete="nationality-name"
                onChange={(e) => setNationality(e.target.value)}
                className="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
              >
                <option>Indian</option>
                <option>Other</option>
              </select>
              {getErrorText(nationalityError)}
            </div>
          </div>
          <div className="sm:col-span-2">
            <label
              htmlFor="marital-status"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Marital Status
            </label>
            <div className="mt-2">
              <select
                id="marital-status"
                name="marital-status"
                autoComplete="marital-status-name"
                onChange={(e) => setMaritalStatus(e.target.value)}
                className="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
              >
                <option>Single</option>
                <option>Married</option>
                <option>Widow</option>
              </select>
              {getErrorText(maritalStatusError)}
            </div>
          </div>

          <div className="col-span-full">
            <label
              htmlFor="street-address"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Address
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="street-address"
                id="street-address"
                autoComplete="street-address"
                onChange={(e) => handleChange(e, setAddress, setAddressError)}
                className={getClassName(addressError)}
              />
              {getErrorText(addressError)}
            </div>
          </div>

          <div className="sm:col-span-2">
            <label
              htmlFor="postal-code"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              PIN code
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="postal-code"
                id="postal-code"
                autoComplete="postal-code"
                onChange={(e) => handleChange(e, setPinCode, setPinCodeError)}
                className={getClassName(pinCodeError)}
              />
              {getErrorText(pinCodeError)}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const qualificationInfo = () => {
    return (
      <div className="border-b border-gray-900/10 pb-12">
        <h2 className="text-base font-semibold leading-7 text-gray-900">
          Qualification & Experience Information
        </h2>

        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div className="sm:col-span-3">
            <label
              htmlFor="post-info"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Highest Qualification
            </label>
            <div className="mt-2">
              <select
                id="hightest-qualification"
                name="hightest-qualification"
                autoComplete="hightest-qualification"
                className="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                value={highestQualification}
                onChange={(e) => setHighestQualification(e.target.value)}
              >
                <option disabled selected>
                  Select one
                </option>
                {QualificationList.slice(post === "RWO" ? 6 : 0).map(
                  (education, index) => (
                    <option key={index}>
                      {post === "RWO" && index === 0
                        ? "Graduation in any Science"
                        : education}
                    </option>
                  )
                )}
              </select>
            </div>
          </div>

          <div className="sm:col-span-3">
            <label
              htmlFor="post-info"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Stream in 12th Class
            </label>
            <div className="mt-2">
              <select
                id="hightest-qualification"
                name="hightest-qualification"
                autoComplete="hightest-qualification"
                className="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                value={twelveStream}
                onChange={(e) => setTwelveStream(e.target.value)}
              >
                <option disabled selected>
                  Select one
                </option>
                {post !== "RWO" && <option>Commerce</option>}
                {post !== "RWO" && <option>Arts</option>}
                <option>Science</option>
              </select>
            </div>
          </div>

          <div className="sm:col-span-3 space-y-4">
            {post === "AWO" ? (
              <>
                <div>
                  <label
                    htmlFor="10th-percentage"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    10th Class Percentage
                  </label>
                  <div className="mt-2">
                    <input
                      type="number"
                      inputMode="numeric"
                      name="10th-percentage"
                      id="10th-percentage"
                      autoComplete="10th-percentage"
                      onChange={(e) =>
                        handleChange(
                          e,
                          setTenthPercentage,
                          setTenthPercentageError
                        )
                      }
                      className={getClassName(tenthPercentageError)}
                    />
                    {getErrorText(tenthPercentageError)}
                  </div>
                </div>
              </>
            ) : (
              <>
                <label
                  htmlFor="highest-percentage"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Percentage of Highest Education
                </label>
                <div className="mt-2">
                  <input
                    type="number"
                    inputMode="numeric"
                    name="highest-percentage"
                    id="highest-percentage"
                    autoComplete="highest-percentage"
                    value={highestQualificationPercent}
                    onChange={(e) =>
                      handleChange(
                        e,
                        setHighestQualificationPercent,
                        setHighestQualificationPercentError
                      )
                    }
                    className={getClassName(highestQualificationPercentError)}
                  />
                  {getErrorText(highestQualificationPercentError)}
                </div>
              </>
            )}
          </div>
          {post === "AWO" && (
            <div className="sm:col-span-3">
              <label
                htmlFor="12th-percentage"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                12th Class Percentage
              </label>
              <div className="mt-2">
                <input
                  type="number"
                  inputMode="numeric"
                  name="12th-percentage"
                  id="12th-percentage"
                  autoComplete="12th-percentage"
                  onChange={(e) =>
                    handleChange(
                      e,
                      setTwelvePercentage,
                      setTwelvePercentageError
                    )
                  }
                  className={getClassName(twelvePercentageError)}
                />
                {getErrorText(twelvePercentageError)}
              </div>
            </div>
          )}
          <div className="sm:col-span-4">
            <label
              htmlFor="tech-education"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Mention the details of your Technical Education, if any
            </label>
            <div className="mt-2">
              <input
                id="tech-education"
                name="tech-education"
                type="tech-education"
                autoComplete="tech-education"
                onChange={(e) => setTechnicalEducation(e.target.value)}
                className={getClassName(technicalEducationError)}
              />
              {getErrorText(technicalEducationError)}
            </div>
          </div>
          <div className="col-span-full">
            <label
              htmlFor="about-experience"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Write about your Experience briefly, if any
            </label>
            <div className="mt-2">
              <textarea
                id="about-experience"
                name="about-experience"
                rows={3}
                className={getClassName(experienceError)}
                defaultValue={""}
                maxLength={expierenceMaxLength}
                onChange={(e) => {
                  if (e.target.value.length <= expierenceMaxLength) {
                    setExperience(e.target.value);
                  }
                }}
              />
              <div className="flex w-full justify-between">
                {getErrorText(experienceError)}
                <span className="ml-auto text-xs md:text-sm">
                  {expierenceMaxLength - experience.length} characters remaining
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const applicationFee = () => {
    return (
      <div className="border-b border-gray-900/10 pb-12">
        <h2 className="text-lg font-semibold leading-7 text-gray-900">
          Application Fee
        </h2>
        <label
          htmlFor="any"
          className="block text-sm font-medium leading-6 text-gray-900 mt-5"
        >
          Don't forget to take a <b>Screenshot of the Transaction</b> for
          providing as a proof of payment.
        </label>
        <div style={{ maxWidth: 800 }}>
          {JobApplicationFee.filter(
            (item) => item.post.toUpperCase() === post
          ).map((item, index) => {
            const modifiedFee = item.fee + 100;

            return (
              <div
                key={index}
                tabIndex={0}
                className={`collapse collapse-plus border border-base-300 bg-base-200 mt-4 ${
                  index === selectedIdx ? "selected" : ""
                }`}
              >
                <input
                  type="checkbox"
                  checked={index === selectedIdx}
                  onChange={() => handleCheckboxChange(index)}
                />
                <div className="collapse-title text-lg font-bold md:text-xl">
                  {`Click here to Scan and Pay the Application Fee for ${post}`}
                </div>
                <div className="collapse-content text-center">
                  <div
                    key={index}
                    className="flex flex-col justify-center items-center "
                  >
                    <p className="text-2xl mt-2 font-bold whitespace-pre-line">
                      {`Pay Recruitment Test Fees of Rs. ${modifiedFee}/- for ${post}
Scan Any QR Code to Pay
Recipient Name:- Optim Health and Wellness OPC Pvt. Ltd.`}
                    </p>
                    <p className="text-xl mt-5 text-red-600 font-bold whitespace-pre-line">
                      {`Be careful - 
Form will be rejected if you pay less than Rs. ${modifiedFee}/-
Extra amount will be refunded if you pay greater than Rs. ${modifiedFee}/-`}
                    </p>
                    <p className="text-xl mt-5">
                      After finalizing the transaction, please provide the{" "}
                      transaction number and upload the corresponding
                      transaction screenshot below.
                    </p>
                    <img
                      className="mt-10"
                      src={qrCodeUrl}
                      width={`100%`}
                      style={{
                        maxWidth: 800,
                      }}
                    />
                    <br />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div className="sm:col-span-4">
            <label
              htmlFor="txnNumber"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Transaction Number
            </label>
            <span className="text-sm font-medium leading-6 text-red-500 mt-5">
              Do not reuse the same transaction number for multiple forms. Else
              it will be rejected.
            </span>
            <div className="mt-2">
              <input
                id="txnNumber"
                name="txnNumber"
                type="text"
                onChange={(e) =>
                  handleChange(e, setTxnNumber, setTxnNumberError)
                }
                className={getClassName(txnNumberError)}
              />
              {getErrorText(txnNumberError)}
            </div>
          </div>
        </div>

        <div className="sm:col-span-4 mt-4">
          <label
            htmlFor="txnproof"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Upload Proof of the Transaction
          </label>
          <p className="text-sm font-medium leading-6 text-gray-900">
            Transaction Screenshot - (Upto 1MB Image only)
          </p>
          <div className="mt-2 flex flex-col">
            <input
              id="txnproof"
              type="file"
              className="file-input file-input-bordered file-input-warning w-full max-w-xs"
              onChange={(e) =>
                handleFileChange(e, setPaymentUrl, setPaymentKey)
              }
            />
            {getErrorText(paymentUrlError)}
          </div>
        </div>
      </div>
    );
  };

  const documentSection = () => {
    return (
      <div className="border-b border-gray-900/10 pb-12">
        <h2 className="text-base font-semibold leading-7 text-gray-900">
          Documents Information
        </h2>
        <label
          htmlFor="any"
          className="block text-sm font-medium leading-6 text-red-500 mt-5"
        >
          You need to upload the following documents.
        </label>

        <div className="flex flex-col gap-3">
          <div className="sm:col-span-4 mt-4">
            <label
              htmlFor="passport"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Passport Photo - (Upto 1MB Image only)
            </label>
            <div className="mt-2 flex flex-col">
              <input
                type="file"
                id="passport"
                className="file-input file-input-bordered file-input-warning w-full max-w-xs"
                onChange={(e) =>
                  handleFileChange(e, setPassportUrl, setPassportUrlKey)
                }
              />
              {getErrorText(passportUrlError)}
            </div>
          </div>

          <div className="sm:col-span-4 mt-4">
            <div className="mt-2 form-control">
              <label
                htmlFor="AadharFront"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Aadhar Card Front - (Upto 1MB Image only)
              </label>
              <input
                type="file"
                id="AadharFront"
                className="file-input file-input-bordered file-input-warning w-full max-w-xs"
                onChange={(e) =>
                  handleFileChange(
                    e,
                    setAadharCardFrontUrl,
                    setAadharCardFrontUrlKey
                  )
                }
              />
              {getErrorText(aadharCardFrontUrlError)}
            </div>
          </div>

          <div className="sm:col-span-4 mt-4">
            <label
              htmlFor="AadharBack"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Aadhar Card Back - (Upto 1MB Image only)
            </label>
            <input
              type="file"
              id="AadharBack"
              className="file-input file-input-bordered file-input-warning w-full max-w-xs"
              onChange={(e) =>
                handleFileChange(
                  e,
                  setAadharCardBackUrl,
                  setAadharCardBackUrlKey
                )
              }
            />
            {getErrorText(aadharCardBackUrlError)}
          </div>

          <div className="sm:col-span-4 mt-4">
            <label
              htmlFor="signature"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Signature - (Upto 1MB Image only)
            </label>
            <div className="mt-2 flex flex-col">
              <input
                type="file"
                id="signature"
                className="file-input file-input-bordered file-input-warning w-full max-w-xs"
                onChange={(e) =>
                  handleFileChange(e, setSignatureUrl, setSignatureUrlKey)
                }
              />
              {getErrorText(signatureUrlError)}
            </div>
          </div>
        </div>

        <div className="sm:col-span-4 mt-8">
          <label
            htmlFor="handwriting"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            <span className="text-lg">
              Handwriting sample of the following sentences.
            </span>{" "}
            <br /> Write in a white blank page, put Signature, Click and Upload
            (Upto 1MB Image only)
          </label>
          <p
            className="text-sm mt-4 mb-4 flex flex-col p-3 outline outline-1"
            style={{ maxWidth: 400 }}
          >
            &emsp;&emsp; I have hereby declared that all statements made in the
            application form are true, complete and correct to the best of my
            knowledge and belief. I have read all the contents, rules, terms and
            conditions mentioned in the advertisement and I agree to abide by
            the rules, regulations and procedures for Examination or Training or
            Appointment to the posts I have applied for.
            <br />
            <br />
            <span className="text-end w-full">
              (Signature of the candidate)
            </span>
          </p>
          <div className="mt-2 flex flex-col">
            <input
              id="handwriting"
              type="file"
              className="file-input file-input-bordered file-input-warning w-full max-w-xs"
              onChange={(e) =>
                handleFileChange(e, setHandWritingUrl, setHandWritingUrlKey)
              }
            />
            {getErrorText(handWritingUrlError)}
          </div>
        </div>
        <div className="flex flex-col gap-4 mt-4">
          {post === "AWO" && (
            <div className="sm:col-span-4 mt-4">
              <label
                htmlFor="tenthMarksheet"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                10th Pass Marksheet - (Upto 1MB Image only)
              </label>
              <div className="mt-2 flex flex-col">
                <input
                  type="file"
                  className="file-input file-input-bordered file-input-warning w-full max-w-xs"
                  onChange={(e) =>
                    handleFileChange(e, setTenthMarksheet, setTenthMarksheetKey)
                  }
                />
                {getErrorText(tenthMarksheetError)}
              </div>
            </div>
          )}
          <div className="sm:col-span-4 mt-4">
            <label
              htmlFor="tech-education"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              12th Pass Marksheet - (Upto 1MB Image only)
            </label>
            <div className="mt-2 flex flex-col">
              <input
                type="file"
                className="file-input file-input-bordered file-input-warning w-full max-w-xs"
                onChange={(e) =>
                  handleFileChange(e, setTwelveMarksheet, setTwelveMarksheetKey)
                }
              />
              {getErrorText(twelveMarksheetError)}
            </div>
          </div>
          <div className="sm:col-span-4 mt-4">
            <label
              htmlFor="tech-education"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Highest Education Marksheet - (Upto 1MB Image only)
            </label>
            <div className="mt-2 flex flex-col">
              <input
                type="file"
                className="file-input file-input-bordered file-input-warning w-full max-w-xs"
                onChange={(e) =>
                  handleFileChange(
                    e,
                    setHighestMarksheet,
                    setHighestMarksheetKey
                  )
                }
              />
              {getErrorText(highestMarksheetError)}
            </div>
          </div>
        </div>
        <div className="mt-16 w-full flex flex-col justify-center items-center gap-5">
          {isSubmitDisabled && !isSubmitSuccess && (
            <span className="text-red-500">
              Please fill all the required fields
            </span>
          )}
          {!isSubmitSuccess && submitError && (
            <span className="text-red-500 text-center text-xs md:text-base">
              Something went wrong.
              <br />
              Please verify all fields or contact administration via email:{" "}
              <a href="mailto:info@nhwm.in" className="underline">
                info@nhwm.in
              </a>{" "}
              or call us at <a href="tel:+913617968083">+913617968083</a>.
            </span>
          )}
          {isSubmitSuccess && (
            <span className="text-green-500 text-center">
              Thanks for submitting your form. <br /> Please check your email
              inbox for Ordering Update. (Check spam or bin folder of
              your email also)
            </span>
          )}
          <div className="mx-auto">
            {!isSubmitSuccess && (
              <div className="flex justify-between gap-3 text-xs md:text-base">
                <input
                  className="checkbox checkbox-primary text-white border-2"
                  type="checkbox"
                  checked={termsAgreed}
                  onChange={(e) => setTermsAgreed(e.target.checked)}
                />
                <span>I have read and accept the TERMS and CONDITIONS</span>
              </div>
            )}
          </div>
          <button
            className={`btn btn-wide bg-green-500 text-white hover:bg-green-600 ${
              isLoading || isSubmitSuccess || !termsAgreed
                ? "btn-disabled hover:cursor-not-allowed"
                : ""
            }`}
            onClick={onSubmitApplication}
          >
            {isLoading ? "Processing..." : "Submit"}
          </button>
          <span className="text-red-500 text-xs md:text-base font-bold text-center">
            {!isSubmitSuccess &&
              "Please verify all details are correct before submitting."}
          </span>
        </div>
      </div>
    );
  };

  const onSubmitApplication = async () => {
    try {
      const checkField = (
        field: string,
        setError: React.Dispatch<React.SetStateAction<boolean>>
      ) => {
        if (!field) {
          setIsSubmitDisabled(true);
          setError(true);
          return false;
        } else {
          setError(false);
          return true;
        }
      };

      let areAllFieldsValid =
        checkField(post, setPostError) &&
        checkField(state, setStateError) &&
        (post === "DWO" || post === "AWO"
          ? checkField(district, setDistrictError)
          : true) &&
        checkField(firstName, setFirstNameError) &&
        checkField(lastName, setLastNameError) &&
        checkField(email, setEmailError) &&
        checkField(phone, setPhoneError) &&
        checkField(whatsApp, setWhatsAppError) &&
        checkField(fatherName, setFatherNameError) &&
        checkField(motherName, setMotherNameError) &&
        checkField(dateOfBirth, setDateOfBirthError) &&
        checkField(gender, setGenderError) &&
        checkField(category, setCategoryError) &&
        checkField(nationality, setNationalityError) &&
        checkField(maritalStatus, setMaritalStatusError) &&
        checkField(address, setAddressError) &&
        checkField(pinCode, setPinCodeError) &&
        checkField(highestQualification, setHighestQualificationError) &&
        (post === "AWO"
          ? true
          : checkField(
              highestQualificationPercent,
              setHighestQualificationPercentError
            )) &&
        checkField(twelveStream, setTwelveStreamError) &&
        checkField(txnNumber, setTxnNumberError) &&
        checkField(paymentUrl, setPaymentUrlError) &&
        checkField(passportUrl, setPassportUrlError) &&
        checkField(aadharCardFrontUrl, setAadharCardFrontUrlError) &&
        checkField(aadharCardBackUrl, setAadharCardBackUrlError) &&
        checkField(signatureUrl, setSignatureUrlError) &&
        checkField(twelveMarksheet, setTwelveMarksheetError);
      checkField(signatureUrl, setSignatureUrlError) &&
        checkField(handWritingUrl, setHandWritingUrlError) &&
        checkField(twelveMarksheet, setTwelveMarksheetError) &&
        checkField(highestMarksheet, setHighestMarksheetError);

      if (post === "AWO") {
        areAllFieldsValid =
          areAllFieldsValid &&
          checkField(tenthMarksheet, setTenthMarksheetError) &&
          checkField(tenthPercentage, setTenthPercentageError) &&
          checkField(twelvePercentage, setTwelvePercentageError);
      }

      if (post === "HRWO") {
        areAllFieldsValid =
          areAllFieldsValid && checkField(hrwoOption, setHrwoOptionError);
      }

      if (areAllFieldsValid) {
        setIsLoading(true);
        const { status, data } = await onApplyJob({
          Email: email,
          Phone: phone,
          WhatsApp: whatsApp,
          FirstName: firstName,
          MiddleName: middleName || " ",
          LastName: lastName,
          FathersName: fatherName,
          MothersName: motherName,
          DateOfBirth: dateOfBirth,
          Gender: gender,
          Status: maritalStatus,
          Nationality: nationality,
          Cast: category,
          JobApplication: [
            {
              Id: String(
                availablePosts.findIndex(
                  (availablePost) => post === availablePost
                )
              ),
              Post: `${post === "HRWO" ? `${post} ${hrwoOption}` : `${post}`}`,
              State: state,
              District: district || "no district chosen",
            },
          ],
          Education: {
            TwelveStream: twelveStream,
            HighestEducation: highestQualification,
            TenthPercentage: tenthPercentage,
            TwelvePercentage: twelvePercentage,
            HighestPercentage: highestQualificationPercent,
            TechnicalEducation: technicalEducation,
            Experience: experience,
          },
          Address: {
            address: address,
            PostCode: pinCode,
          },
          Docs: [
            {
              Title: "Aadhar Card Front",
              Url: aadharCardFrontUrlKey,
            },
            {
              Title: "Aadhar Card Back",
              Url: aadharCardBackUrlKey,
            },
            {
              Title: "Passport Photo",
              Url: passportUrlKey,
            },
            {
              Title: "Signature",
              Url: signatureUrlKey,
            },
            {
              Title: "Hand Writing Sample",
              Url: handWritingUrlKey,
            },
            {
              Title: "10th Marksheet",
              Url: tenthMarksheetKey,
            },
            {
              Title: "12th Marksheet",
              Url: twelveMarksheetKey,
            },
            {
              Title: "Highest Education Marksheet",
              Url: highestMarksheetKey,
            },
          ],
          Payments: [{ TransactionId: txnNumber, FileUrl: paymentKey }],
        });
        if (status === 200) {
          setIsSubmitSuccess(true);
          setSubmitError(false);
        }
      } else {
        setIsSubmitDisabled(true);
      }
    } catch (error) {
      setSubmitError(true);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex justify-center items-center navbar-offset">
      <div className="space-y-12 p-3">
        {jobInfo()}
        {personalInfo()}
        {qualificationInfo()}
        {applicationFee()}
        {documentSection()}
      </div>
    </div>
  );
};
