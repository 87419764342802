import axios from "axios";

export const onApplyJob = async (input: any) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/apply`,
      input
    );
    return { status: response.status, data: response.data };
  } catch (error) {
    console.log(error);
    return {
      msg: "error occured",
    };
  }
};

export const GetUploadUrl = async (file_name: string) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/getuploadurl?file=${file_name}`
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return {
      msg: "error occured",
    };
  }
};

export const onOrderBook = async (input: any) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/place-order`,
      input
    );
    return { status: response.status, data: response.data };
  } catch (error) {
    console.log(error);
    return {
      msg: "error occured",
    };
  }
};
