import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Home } from "./pages/Home";
import { Navbar } from "./pages/Components/Navbar";
import { Footer } from "./pages/Components/Footer";
import { Intimations } from "./pages/Intimations";
import { ApplyJob } from "./pages/Apply/index";
import { TnC } from "./pages/tnc";
import { Disabled } from "./pages/Disabled";
import { Maintenance } from "./pages/Maintenance";
import { OexPage } from "./pages/oex/oex";
import { OrderBookDisabled } from "./pages/OrderBook/order-book-disabled";

interface ValidRoutesProps {}
const ValidRoutes: React.FC<ValidRoutesProps> = () => {
  return (
    <Routes>
      <Route path="/il" element={<Intimations />} />
      <Route path={`/${process.env.REACT_APP_APPLY_ROUTE}`} element={<ApplyJob />} />
      <Route path="/job" element={<Disabled />} />
      <Route path="/book" element={<OrderBookDisabled />} />
      <Route path="/tnc" element={<TnC />} />
      <Route path="/" element={<Home />} />
      <Route path="/oex" element={<OexPage />} />
      <Route path="/maintenance" element={<Maintenance />} />
    </Routes>
  );
};

interface RouterHandlerProps {}

export const RouterHandler: React.FC<RouterHandlerProps> = () => {
  return (
    <BrowserRouter>
      <div className="flex flex-col min-h-screen justify-between">
        <Navbar />
        <ValidRoutes />
        <Footer />
      </div>
    </BrowserRouter>
  );
};
