export const Maintenance = () => {
  return (
    <div className="text-black text-center space-y-10 h-[70vh] flex flex-col justify-center p-3">
      <div className="text-2xl font-bold text-gray-600 lg:text-5xl">
        Our organization's main website is under maintenance. <br />
        <br /> It will be back very soon.
      </div>
    </div>
  );
};
