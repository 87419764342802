import "./font.css";
export const OexPage = () => {
  return (
    <div className="text-black text-center space-y-10 h-[70vh] flex flex-col justify-center p-3">
      <div className="text-2xl font-bold opacity-60 lg:text-5xl">
        Resumes and CVs submission for <br /> Office Assistant cum Front Desk Executive is over.
      </div>
    </div>
  );
};
