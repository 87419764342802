import { useEffect } from "react";
import {
  IntimationLetters,
  IntimationLettersToCMO,
  IntimationLettersToDGP,
  IntimationLettersToDHS,
} from "../constants/constants";
import REACTGA from "react-ga4";

export const Intimations = () => {
  useEffect(() => {
    REACTGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: "Intimations Page",
    });
  }, []);
  return (
    <div className="flex items-start flex-col ml-3 mr-3 mb-3 navbar-offset mt-[0.5cm]">
      <a
        href="/"
        className="bg-primary p-2 px-5 rounded-xl text-xs md:text-sm font-bold self-end hover:bg-[#8abbc5]"
      >
        &#x2190; Back
      </a>
      <br />
      <div className="mx-auto">
        <div className="text-secondary font-extrabold text-2xl md:text-4xl">
          Intimation Letters :-
        </div>
        <a
          href="https://nhwm-job-portal.s3.ap-south-1.amazonaws.com/Posting-Proof-of-Intimation-Letters-(Post-Office-Document).pdf"
          target="_blank"
          rel="noreferrer"
          className="link text-[#0000ff] font-bold md:text-xl"
        >
          Click here to see Proof of Intimation Letters (Post Office Documents)
        </a>
        <br />
        <div className="font-bold text-xs md:text-sm">
          Personal Contact No of MD has been erased in the scanned copy of each
          Letter for security purpose.
        </div>
        <br />
        <div className="flex flex-col gap-3 md:gap-7">
          {IntimationLetters.map((item, index) => (
            <div
              key={index}
              className="collapse collapse-plus bg-[#045cb4] hover:bg-[hsl(210,96%,46%)]"
            >
              <input type="checkbox" />
              <div className="collapse-title text-sm md:text-xl font-medium text-white">
                {item.title}
              </div>
              <div className="collapse-content">
                <a href={item.href} rel="noreferrer" target="_blank">
                  <img src={item.href} alt={item.title} />
                </a>
              </div>
            </div>
          ))}
        </div>
        <br />
        <div className="font-extrabold text-[1.15rem] md:text-3xl text-[#A70000] text-center">
          Intimation Letters To CMO :-
        </div>
        <br />
        <div className="grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-7">
          {IntimationLettersToCMO.map((item, index) => (
            <div
              key={index}
              className="collapse collapse-plus bg-[#045cb4] hover:bg-[hsl(210,96%,46%)]"
            >
              <input type="checkbox" />
              <div className="collapse-title text-sm md:text-xl font-medium text-white">
                {item.title}
              </div>
              <div className="collapse-content">
                <a href={item.href} rel="noreferrer" target="_blank">
                  <img src={item.href} alt={item.title} />
                </a>
              </div>
            </div>
          ))}
        </div>
        <br />
        <div className="font-extrabold text-[1.15rem] md:text-3xl text-[#A70000] text-center">
          Intimation Letters to Director of Health Services (All States) :-
        </div>
        <br />
        <div className="grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-7">
          {IntimationLettersToDHS.map((item, index) => (
            <div
              key={index}
              className="collapse collapse-plus bg-[#045cb4] hover:bg-[hsl(210,96%,46%)]"
            >
              <input type="checkbox" />
              <div className="collapse-title text-sm md:text-xl font-medium text-white">
                {item.title}
              </div>
              <div className="collapse-content">
                <a href={item.href} rel="noreferrer" target="_blank">
                  <img src={item.href} alt={item.title} />
                </a>
              </div>
            </div>
          ))}
        </div>
        <br />
        <div className="font-extrabold text-[1.15rem] md:text-3xl text-[#A70000] text-center">
          Intimation Letters to Director General of Police (All States) :-
        </div>
        <br />
        <div className="grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-7">
          {IntimationLettersToDGP.map((item, index) => (
            <div
              key={index}
              className="collapse collapse-plus bg-[#045cb4] hover:bg-[hsl(210,96%,46%)]"
            >
              <input type="checkbox" />
              <div className="collapse-title text-sm md:text-xl font-medium text-white">
                {item.title}
              </div>
              <div className="collapse-content">
                <a href={item.href} rel="noreferrer" target="_blank">
                  <img src={item.href} alt={item.title} />
                </a>
              </div>
            </div>
          ))}
        </div>
        <br />
        <div className="text-secondary text-xl md:text-3xl font-bold text-center">
          Come on - Join Us...
        </div>
        <div className="text-secondary text-xl md:text-3xl font-bold text-center">
          We want to see you participating in our MISSION.
        </div>
        <br />
      </div>
    </div>
  );
};
