import { useLocation } from "react-router-dom";
import logo from "../../images/logo.png";
import optionsvg from "../../images/options-svgrepo-com.svg";

export const Navbar = () => {
  // const navigate = useNavigate();
  const location = useLocation();

  const isApplicationsRoute = location.pathname === "/job";
  const isIntimationRoute = location.pathname === "/il";

  return (
    <div className={`navbar bg-slate-50 sticky top-0 z-50 navbar-offset`}>
      <div className="flex-1">
        <a className="" href="/">
          <div className="avatar justify-center items-center gap-2">
            <div className="w-20 rounded-xl">
              <img src={logo} alt="Logo" />
            </div>
            <p className="text-lg lg:text-3xl xl:text-4xl font-bold text-start text-sky-500">
              National Healthcare & Wellness Mission
            </p>
          </div>
        </a>
      </div>
      <div className="hidden md:block md:flex-none">
        <div className="text-gray-500 order-3 w-full md:w-auto md:order-2">
          <ul className="flex font-semibold justify-between gap-2">
            <li className="btn bg-slate-200 rounded-2 md:px-4 md:py-2 hover:text-sky-500 transition duration-300">
              <a href="/book">Order Guidebook</a>
            </li>
            <li className="btn bg-slate-200 rounded-2 md:px-4 md:py-2 hover:text-sky-500 transition duration-300">
              <a href="/job">Applications</a>
            </li>
            <li className="btn bg-slate-200 rounded-2 md:px-4 md:py-2 hover:text-sky-500 transition duration-300">
              <a href="/il">Intimations</a>
            </li>
          </ul>
        </div>
      </div>
      <details className="dropdown dropdown-end md:hidden">
        <summary className="list-none">
          <img
            src={optionsvg}
            tabIndex={0}
            role="button"
            className="bg-slate-200 p-2 rounded w-10"
            alt="Options"
          ></img>
        </summary>
        <ul
          tabIndex={0}
          className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52"
        >
          <li>
            <a href="/book" target="_blank">
              Order Guidebook
            </a>
          </li>
          <li>
            <a href="/job" target="_blank">
              Applications
            </a>
          </li>
          <li>
            <a href="/il" target="_blank">
              Intimations
            </a>
          </li>
        </ul>
      </details>
    </div>
  );
};

export default Navbar;
