export interface Link {
  label: string;
  href: string;
  className?: string;
}

export interface FormSet {
  links: Link[];
}

const BtnFormPrimaryStyle =
  "btn btn-secondary w-full hover:bg-primary hover:text-secondary";
const BtnFormSecondaryStyle =
  "bg-slate-300 outline outline-1 text-xs md:text-sm w-11/12";

export const HeadForms: FormSet[] = [
  {
    links: [
      {
        label: "Online Form For RWO (With Late Fees)",
        href: "https://docs.google.com/forms/d/e/1FAIpQLSdEaDZErsZiXC1Ss1-TeFVlp0AAbJ1nSWaEA_LH9W8ERCdyew/viewform",
        className: BtnFormPrimaryStyle,
      },
      {
        label: "Online Form For RWO (Without Late Fees)",
        href: "https://docs.google.com/forms/d/e/1FAIpQLScAZ9h8bw8tOXp4oRer9uYARSz8w0RYWfOUvfIj0Sj_17jTTQ/viewform",
        className: BtnFormPrimaryStyle,
      },
      {
        label: "Sample PDF Copy of filled Form for RWO",
        href: "https://nhwm-job-portal.s3.ap-south-1.amazonaws.com/Sample-Application-form-(RWO).pdf",
        className: BtnFormSecondaryStyle,
      },
    ],
  },
  {
    links: [
      {
        label: "Online Form For HRWO (With Late Fees)",
        href: "https://docs.google.com/forms/d/e/1FAIpQLSf2OJcriVFwNvOUV3YtBff49CPuu7vcxuAt8S0MkSZOvAuLqA/viewform",
        className: BtnFormPrimaryStyle,
      },
      {
        label: "Online Form For HRWO (Without Late Fees)",
        href: "https://docs.google.com/forms/d/e/1FAIpQLSfXZZsX7xr8np3dpWH3AhFmns2Ax7ugDT4lXSiS6Du3uD5kvQ/viewform",
        className: BtnFormPrimaryStyle,
      },
      {
        label: "Sample PDF Copy of filled Form for HRWO",
        href: "https://nhwm-job-portal.s3.ap-south-1.amazonaws.com/Sample-Application-form-(HRWO).pdf",
        className: BtnFormSecondaryStyle,
      },
    ],
  },
  {
    links: [
      {
        label: "Online Form For ITWO (With Late Fees)",
        href: "https://docs.google.com/forms/d/e/1FAIpQLSdx8Va0BUTFImjexO6_P-FaZKMEz8W28uBIEVn2EW1cRVXhtw/viewform",
        className: BtnFormPrimaryStyle,
      },
      {
        label: "Online Form For ITWO (Without Late Fees)",
        href: "https://docs.google.com/forms/d/e/1FAIpQLSdt50EzDnX5x9wQ_luOJ8wM7bc10BeBHcpoon_CI7_JgdWQxA/viewform",
        className: BtnFormPrimaryStyle,
      },
      {
        label: "Sample PDF Copy of filled Form for ITWO",
        href: "https://nhwm-job-portal.s3.ap-south-1.amazonaws.com/Sample-Application-form-(ITWO).pdf",
        className: BtnFormSecondaryStyle,
      },
    ],
  },
];

const createAllFormsButton = (
  state: string,
  DWOhref: string,
  AWOhref: string
) => {
  const DWOButton = {
    label: `DWO Online Form For ${state}`,
    href: DWOhref,
    className: BtnFormPrimaryStyle,
  };

  const AWOButton = {
    label: `AWO Online Form For ${state}`,
    href: AWOhref,
    className: BtnFormPrimaryStyle,
  };

  return [DWOButton, AWOButton];
};

const createLinksArray = (state: string, DWOhref: string, AWOhref: string) => ({
  links: [...createAllFormsButton(state, DWOhref, AWOhref)],
});

export const ALLFORMS: FormSet[] = [
  createLinksArray(
    "Andhra Pradesh",
    "https://docs.google.com/forms/d/e/1FAIpQLSdoZMGgP1G9a6FmHksAgszaZuBBrgXSaVWYTJJy0LMApw75_w/viewform",
    "https://docs.google.com/forms/d/e/1FAIpQLSdP0-56DN5VcSR3hTKCDvwUcu4E-UWL8v1t0WED8hSax-33Zg/viewform"
  ),
  createLinksArray(
    "Arunachal Pradesh",
    "https://docs.google.com/forms/d/e/1FAIpQLSeiY_W7rwHi2sk__Z5GMmjNY-_ulLpY9UnzjY0l9HzkVouR7w/viewform",
    "https://docs.google.com/forms/d/e/1FAIpQLSeATdamU09M4W7Y4dB5KKIfusZo9AM33oCK_jrQ3yvyoxZLBg/viewform"
  ),
  createLinksArray(
    "Assam",
    "https://docs.google.com/forms/d/e/1FAIpQLSd3IZTC5jkECMtE2nL7MOP18EcKm5GIQoYvgCtCY6KHpqb-tg/viewform",
    "https://docs.google.com/forms/d/e/1FAIpQLSfaa165lc1FZ2JW46L6Bfk5GwhvsIn5_RXJ6LXTdRNcAl_zPg/viewform"
  ),
  createLinksArray(
    "Bihar",
    "https://docs.google.com/forms/d/e/1FAIpQLSfW55e6ABxpDJU2CYWJ-wNtb_f6N1xe4Aa_newdsP-3hR7N9A/viewform",
    "https://docs.google.com/forms/d/e/1FAIpQLSfOwarZRe9wZiBPHJpugOkBFX119Ny64R8oyd5XvqqGXT-CRw/viewform"
  ),
  createLinksArray(
    "Chattisgarh",
    "https://docs.google.com/forms/d/e/1FAIpQLSfGtXzrg_DwluYi5EnlY12ti3mqR0q6C_Xmkin0sLzGTc4IWw/viewform",
    "https://docs.google.com/forms/d/e/1FAIpQLSfY5hMJ02NCAx-H2XHwY28bjISnHoKMhk9ssOFY9IK2XRHAJg/viewform"
  ),
  createLinksArray(
    "Delhi",
    "https://docs.google.com/forms/d/e/1FAIpQLSe7YyFEmtY3ObclBWfqGUZPOMX5Yad4zj690v3WoWwV8Q8HWQ/viewform",
    "https://docs.google.com/forms/d/e/1FAIpQLSfhHukNOJr5STtw6NLemJAX3MwxRJYfbzBul3nB4LNthxQCrA/viewform"
  ),
  createLinksArray(
    "Goa",
    "https://docs.google.com/forms/d/e/1FAIpQLSex1BnXqOAW9kfiH9hKqwogSqAmlE-YD4Je-NDUvFpg0mXxxg/viewform",
    "https://docs.google.com/forms/d/e/1FAIpQLSftxsiOcWTvFxh88nCpdREKq9n5IgPt6iIq1hcr2vkkfGPUcA/viewform"
  ),
  createLinksArray(
    "Gujarat",
    "https://docs.google.com/forms/d/e/1FAIpQLSfqAiAeoGNqUdVH8U-FkNXzoh-fADKCBoDbs_72Cct0RtTkwA/viewform",
    "https://docs.google.com/forms/d/e/1FAIpQLScDwbA87OVKO3LR5LR50H4eVfHmL5w9MfWFSGftzhgyLFD6ng/viewform"
  ),
  createLinksArray(
    "Haryana",
    "https://docs.google.com/forms/d/e/1FAIpQLSdhWwhs5MLqO-Ljk_L_w7S51Ts0cEoR5QakapstYWtkPAgAfA/viewform",
    "https://docs.google.com/forms/d/e/1FAIpQLSc1rOhBdN14Q4vxTf1gIgeIC4U4vzkNSDWz3b7E0Bbp6YZsmw/viewform"
  ),
  createLinksArray(
    "Himachal Pradesh",
    "https://docs.google.com/forms/d/e/1FAIpQLSdPOhHnZhmtLwXwzmTvbMezbtAEq5oZoR5orFFrYDDYVDZCXA/viewform",
    "https://docs.google.com/forms/d/e/1FAIpQLSce0_R9Xnbn1IYc98qZ2S2BualJbGpsPjMeR0UAOjhmPMOU6Q/viewform"
  ),
  createLinksArray(
    "Jammu & Kashmir",
    "https://docs.google.com/forms/d/e/1FAIpQLSekPwkKMNRcFs9Ed1AOOfbHgCe86OiVPid9XXWqQDM-Z6r09g/viewform",
    "https://docs.google.com/forms/d/e/1FAIpQLScAE_fn9W6-APmOEALmYBNhp5hyGBhFo9yjSqFTUi85F-m_sQ/viewform"
  ),
  createLinksArray(
    "Jharkhand",
    "https://docs.google.com/forms/d/e/1FAIpQLSd8qgwZhaKXJ8CMdtJ671Jww3QjjXDxI89pMg2uSl4AD-j-PQ/viewform",
    "https://docs.google.com/forms/d/e/1FAIpQLSefWw07Ed_SmtiA9Muzcc_D5mzqhILi7TXvpcf7WFMqcBF7Cw/viewform"
  ),
  createLinksArray(
    "Karnataka",
    "https://docs.google.com/forms/d/e/1FAIpQLSeW7xCeZ29ppnKLtLrgXzktSkAStGO4hkYJH5VrXiSSaBQI-w/viewform",
    "https://docs.google.com/forms/d/e/1FAIpQLSfx8nadxBP3LOP6TEtu8_U5SNrlvaZKfQOaHg2tRUSRM6uMCQ/viewform"
  ),
  createLinksArray(
    "Kerala",
    "https://docs.google.com/forms/d/e/1FAIpQLScaypyC7iK4EAQpBKI5jhWWpklxvhlOKxYp1SNoshbM_WmA6w/viewform",
    "https://docs.google.com/forms/d/e/1FAIpQLSef3Ahqxsh0VnWWecPfs6XwVMWsv2TRPa28RmnIX47yPr-5sg/viewform"
  ),
  createLinksArray(
    "Madhya Pradesh",
    "https://docs.google.com/forms/d/e/1FAIpQLSdMfG3TUVfe8hV8peAMJzo7RSvk2Wp4Gk7cEiDxuDy0-rInLw/viewform",
    "https://docs.google.com/forms/d/e/1FAIpQLSfQtLQUU0C6PPTDz3540x7eQ6I9LuhFq44QVtmd2nHHONeUQg/viewform"
  ),
  createLinksArray(
    "Maharashtra",
    "https://docs.google.com/forms/d/e/1FAIpQLSdpMD7kGPQcVU5Ccp-neeR9iThokeVmU4rN_L58KW01wkrepg/viewform",
    "https://docs.google.com/forms/d/e/1FAIpQLSdSe1gtnlSmudiGu8ymS4hJy_RvuOePNKbvBnXB8dixpSieJw/viewform"
  ),
  createLinksArray(
    "Manipur",
    "https://docs.google.com/forms/d/e/1FAIpQLSd9JW0t_fu8ePF0wO2CyXkWx77jWn6Qv6erO-zz6lsF_S0eHw/viewform",
    "https://docs.google.com/forms/d/e/1FAIpQLSe-xF9dIca_phBFpvEv_2258-8kUwuFslVmBtwz0tS9ymxf_A/viewform"
  ),
  createLinksArray(
    "Meghalaya",
    "https://docs.google.com/forms/d/e/1FAIpQLSf9bwQOU9vQyCYNkfqsY43bMPb9flrsUZSOZs-2fhH_vMuUQQ/viewform",
    "https://docs.google.com/forms/d/e/1FAIpQLSfLlwkNcXl3uRQKAGxllnzQU3vih6PCA4dPIDyEAfnIY-Is7g/viewform"
  ),
  createLinksArray(
    "Mizoram",
    "https://docs.google.com/forms/d/e/1FAIpQLScsJRfpIZNThXT0CH1PUGIFHa6sj2W8fX1aO-l6aycrVmrzwQ/viewform",
    "https://docs.google.com/forms/d/e/1FAIpQLSd6RbdMih1kjN8zQDK_djNXq3UwhuuDxckRY8oTbG5QzSFpaQ/viewform"
  ),
  createLinksArray(
    "Nagaland",
    "https://docs.google.com/forms/d/e/1FAIpQLSetASqVS0adI8wiUnbMGt97IZQ5NU5jNmA9HdxjLP0jTHSu2w/viewform",
    "https://docs.google.com/forms/d/e/1FAIpQLSd3Mk7V1vjf-tl_goSAHzgl268--38HFrjd_bd-FKEbw2N9Vg/viewform"
  ),
  createLinksArray(
    "Orissa",
    "https://docs.google.com/forms/d/e/1FAIpQLSfRi6JNZnqzpLY3IHO32X_TW13XCzPRfJ4GjYZBM96XFVcJDA/viewform",
    "https://docs.google.com/forms/d/e/1FAIpQLSek_IFcafmEV-7jKK22fRVe4ddoo2hjxdNV9jrB7QmJBk66cA/viewform"
  ),
  createLinksArray(
    "Puducherry",
    "https://docs.google.com/forms/d/e/1FAIpQLSf0T4zN9OuvIAVGUimS6MVvi9YCHdUUhQKrq-USbCKT08Nu2A/viewform",
    "https://docs.google.com/forms/d/e/1FAIpQLSe0vtVh6rLn9BrTeUJGCEoTiFJMo46kKuBhobfSTYqwe1MOQQ/viewform"
  ),
  createLinksArray(
    "Punjab",
    "https://docs.google.com/forms/d/e/1FAIpQLScn6lL8HOkh-k_M2GfXGHO0y-GSMi2mkjAO0virQtIEzMzNag/viewform",
    "https://docs.google.com/forms/d/e/1FAIpQLSfo5lvIhefQvOijUnnCBu0e_wy-Pa4MCx252GfHNGIsIJgTqQ/viewform"
  ),
  createLinksArray(
    "Rajasthan",
    "https://docs.google.com/forms/d/e/1FAIpQLSehHIXdgRDGrOUzxjyRtS5YNS72-2sKxTdXJ6ud7_LSeynyrw/viewform",
    "https://docs.google.com/forms/d/e/1FAIpQLSdMjsjBabvN6Q7O1yVidpEEAbccECBLw55f83alw5ADHzsiyw/viewform"
  ),
  createLinksArray(
    "Sikkim",
    "https://docs.google.com/forms/d/e/1FAIpQLSdLSvEuU-TLdIK1EEVAC3u7CSSbuSyuLM_hh5IAzlXdnY6kqw/viewform",
    "https://docs.google.com/forms/d/e/1FAIpQLSfkw5zJOCgPNvO9Rl1wJWLZgcdXyJLXoXHIVpvafspqtOG5uw/viewform"
  ),
  createLinksArray(
    "Tamil Nadu",
    "https://docs.google.com/forms/d/e/1FAIpQLSdVr-DFwItBkCJ2mG7_gwSIBQd7nnwB9F9NtJSe_Nz3qKUxzw/viewform",
    "https://docs.google.com/forms/d/e/1FAIpQLScHnejRLwb4hmBIIknm_6NDisOx-qgCjAIWaMEVuM21Zy6qCg/viewform"
  ),
  createLinksArray(
    "Telangana",
    "https://docs.google.com/forms/d/e/1FAIpQLSfCtjT3A08ea6CU9X5DdJ-Vsj2pDuFN6iVF2COWTD4OtrAssg/viewform",
    "https://docs.google.com/forms/d/e/1FAIpQLSf16ARISO0TnSCumBBAiszdnlJ9Lmg4-MNVa2kx9t9bZNsziw/viewform"
  ),
  createLinksArray(
    "Tripura",
    "https://docs.google.com/forms/d/e/1FAIpQLSfLeX3rbOtOcKWSeuRumuqSkbVGgNp972Ab_rXYWoEMGbONAQ/viewform",
    "https://docs.google.com/forms/d/e/1FAIpQLSdgRAkz81o8UnzPWSFTmXT1CWZI2bi7vdkj_44N4U4zGNocAA/viewform"
  ),
  createLinksArray(
    "Uttar Pradesh",
    "https://docs.google.com/forms/d/e/1FAIpQLSeBYMA_y_F6ux9kIDWJ_W6u76ZtW0_NW93SDUiUvk38fmhMYg/viewform",
    "https://docs.google.com/forms/d/e/1FAIpQLSemSqacJIK7NYPGQ6rJGImhk1S_DOkSDS-9vIic9aGAz-NAMA/viewform"
  ),
  createLinksArray(
    "Uttarakhand",
    "https://docs.google.com/forms/d/e/1FAIpQLSeJw3yl5zzxB7kSxSGo2oMeWfUK7eBnPx6V1GDUYvaBdCrq6w/viewform",
    "https://docs.google.com/forms/d/e/1FAIpQLSfwJAHuemikEAfXXBmmN2nqWyYqVesWgybHlpImVp7joP8quA/viewform"
  ),
  createLinksArray(
    "West Bengal",
    "https://docs.google.com/forms/d/e/1FAIpQLSfT7epiIMac-2xXyPv9EClzse3RwEuKWTs0GrgzYkss27WnFA/viewform",
    "https://docs.google.com/forms/d/e/1FAIpQLSepMkn80ZgdSaWGyxdx2O9qnXAbDJy1sMlox6heIrxjAh2Ozw/viewform"
  ),
  createLinksArray(
    "Other States",
    "https://docs.google.com/forms/d/e/1FAIpQLSeNfpKRm56QSx6oT-9saE0Hf4JYwDt5iWG6S6xSiUB2gpHJzA/viewform",
    "https://docs.google.com/forms/d/e/1FAIpQLScQInpC8o33JyO2udv4m8f5hE2c7dkZUwDYNap2bxAS3zE5_w/viewform"
  ),
];

interface IntimationLetter {
  title: string;
  href: string;
}

export const IntimationLetters: IntimationLetter[] = [
  {
    title: "Intimation Letter To Prime Minister Of India",
    href: "https://nhwm-job-portal.s3.ap-south-1.amazonaws.com/images/to-prime-minister.jpeg",
  },
  {
    title: "Intimation Letter To Cabinet Minister Of AYUSH, India",
    href: "https://nhwm-job-portal.s3.ap-south-1.amazonaws.com/images/to-cabinet-minister.jpeg",
  },
  {
    title: "Intimation Letter To Director General Of Health Services, India",
    href: "https://nhwm-job-portal.s3.ap-south-1.amazonaws.com/images/to-director-general-health-service.jpeg",
  },
];

const states: string[] = [
  "Andhra Pradesh",
  "Assam",
  "Chhattisgarh",
  "Goa",
  "Haryana",
  "Jharkhand",
  "Kerala",
  "Maharashtra",
  "Meghalaya",
  "Nagaland",
  "Puducherry",
  "Rajasthan",
  "Tamil Nadu",
  "Tripura",
  "Uttarakhand",
  "Arunachal Pradesh",
  "Bihar",
  "Delhi",
  "Gujarat",
  "Himachal Pradesh",
  "Karnataka",
  "Madhya Pradesh",
  "Manipur",
  "Mizoram",
  "Orissa",
  "Punjab",
  "Sikkim",
  "Telangana",
  "Uttar Pradesh",
  "West Bengal",
];

const statesForDGPIntimation = [...states, "Jammu Kashmir"];

const createIntimationLetterEntry = (
  type: "CMO" | "DHS" | "DGP",
  state: string,
  href: string
) => ({
  title: `Letter to ${type}, ${state}`,
  href: href,
});

export const IntimationLettersToCMO: IntimationLetter[] = states.map((state) =>
  createIntimationLetterEntry(
    "CMO",
    state,
    `https://nhwm-job-portal.s3.ap-south-1.amazonaws.com/images/CMO-${state.replace(
      /\s+/g,
      "-"
    )}.jpg`
  )
);

export const IntimationLettersToDHS: IntimationLetter[] = states.map((state) =>
  createIntimationLetterEntry(
    "DHS",
    state,
    `https://nhwm-job-portal.s3.ap-south-1.amazonaws.com/images/DHS-${state.replace(
      /\s+/g,
      "-"
    )}.jpg`
  )
);

export const IntimationLettersToDGP: IntimationLetter[] =
  statesForDGPIntimation.map((state) =>
    createIntimationLetterEntry(
      "DGP",
      state,
      `https://nhwm-job-portal.s3.ap-south-1.amazonaws.com/images/${state.replace(
        /\s+/g,
        "-"
      )}-Intimation-Letter-to-DGP.jpg`
    )
  );
