import { PaymentModel } from "./types";

export const JobApplicationFee: PaymentModel[] = [
  {
    id: 1,
    post: "AWO",
    fee: 600,
    lateFee: 100,
  },
  {
    id: 2,
    post: "DWO",
    fee: 900,
    lateFee: 100,
  },
  {
    id: 2,
    post: "ITWO",
    fee: 900,
    lateFee: 100,
  },
  {
    id: 2,
    post: "HRWO",
    fee: 900,
    lateFee: 100,
  },
  {
    id: 3,
    post: "RWO",
    fee: 1200,
    lateFee: 100,
  },
];

export const BookSyllabusFee: PaymentModel[] = [
  {
    id: 1,
    post: "",
    fee: 1200,
    lateFee: 0,
  },
];

/*
 {
    id: 4,
    title: "Click here to complete AWO Form with LATE FEE",
    desc: `Pay Recruitment Test Fees of Rs. 700/- for AWO
      Scan Any QR Code to Pay
      Recipient Name:- Optim Health and Wellness OPC Pvt. Ltd.`,
    url: "https://nhwm-job-portal.s3.ap-south-1.amazonaws.com/payment/payment_qr.jpg",
    warning: `Be careful - 
    Form will be rejected if you pay < Rs. 700/-
    Extra amount will be refunded if you pay > Rs. 700/-`,
  },
  {
    id: 5,
    title: "Click here to complete DWO, ITWO & HRWO Form with LATE FEE",
    desc: `Pay Recruitment Test Fees of Rs. 1000/-for DWO, ITWO & HRWO
      Scan Any QR Code to Pay
      Recipient Name:- Optim Health and Wellness OPC Pvt. Ltd.`,
    url: "https://nhwm-job-portal.s3.ap-south-1.amazonaws.com/payment/payment_qr.jpg",
    warning: `Be careful - 
    Form will be rejected if you pay < Rs. 1000/-
    Extra amount will be refunded if you pay > Rs. 1000/-`,
  },
  {
    id: 6,
    title: "Click here to complete RWO Form with LATE FEE",
    desc: `Pay Recruitment Test Fees of Rs. 1300/- for RWO
    Scan Any QR Code to Pay
    Recipient Name:- Optim Health and Wellness OPC Pvt. Ltd.`,
    url: "https://nhwm-job-portal.s3.ap-south-1.amazonaws.com/payment/payment_qr.jpg",
    warning: `Be careful - 
    Form will be rejected if you pay < Rs. 1300/-
    Extra amount will be refunded if you pay > Rs. 1300/-`,
  },
  */
