export const Disabled = () => {
  return (
    <div className="text-black text-center space-y-10 h-[70vh] flex flex-col justify-center p-3">
      <div className="text-2xl font-bold text-[#BF0808] lg:text-5xl">
        Submission of BRT Forms for all states of India is over
      </div>
      <div className="text-center font-bold text-xl">
        If you have any query, please contact us at{" "}
        <a href="tel:03617968083">03617968083</a> from 11:00 AM to 02:00 PM,
        Monday to Friday except the holidays.
        <br />
        OR <br /> Email to us -{" "}
        <a href="mailto:nhwm.oh@gmail.com">nhwm.oh@gmail.com</a>
      </div>
    </div>
  );
};
