import { FAQ, Vacancy } from "./types";

export const Vacancies = (): Vacancy[] => {
  return [
    {
      title: "Regional Head or RWO :- Vacancy - 30 posts",
      qualifications:
        "Graduate or equivalent in any science like Community Science, Life science, Veterinary Science, Agri. Science, MBBS, BE., BTech, Food & Nutrition Science, MBA with 12th pass in Science or MSW with 12th pass in Science",
      preference:
        "Master graduate or equivalent with 12th class in Science\n2 years & above working Experience in Hospital as Medical Officer or Healthcare & Wellness as Senior Executive or Pharmaceutical/Insurance industry as Team Leader",
      serviceType: "Full-time (work from home or office, 10:30 AM to 5:30 PM)",
      salary: "Rs. 50,000/- to 1,00,000/- per month",
      promotion:
        "Applicable with 5% to 20% increment per year or candidate/s will be shifted to Career Progression Programme with no retirement",
      pensionScheme:
        "Available up to 75 years of Age, Retirement – at the age of 65 years",
      mediclaimFacility: "Available for 2 Adult + 2 Child",
      groupTermInsurance: "Rs. 25,00,000/-",
      ageLimit: "25 to 55 years as on 01-01-2024",
      language:
        "Candidate must have the ability to speak fluently (Read and Write also) in the regional language of the region for which he/she opted in the Application Form (consider one or more than one state as one region). Mandatory – English (speak, read & write) Hindi (speak & read)",
      jobResponsibility:
        "Team management; leadership development program for juniors; helping entire regional team in Healthcare & Wellness Awareness, Assessment and Healthcare Counselling Programmes; Monitoring daily activities of juniors; allotment & maintain the record of healthcare tools/foods/supplements allotted to the DWO and AWO; communicating with our 2nd party Vendors those are tied-up with us for supplying of various commodities and also perform other official duties that will be asked by the Mission Director",
      reporting: "Zonal Head OR Mission Director",
    },
    {
      title: "District Officer or DWO :- Vacancy - 650 posts",
      qualifications: "Graduation Pass or equivalent",
      preference:
        "Graduate with intermediate in science or 2 year and above working Experience in Healthcare Industry or Pharmaceutical Industry/Insurance as executive",
      serviceType: "Full-time (work from home or office, 10:30 AM to 5:30 PM)",
      salary: "Rs. 25,000/- to 45,000/- per month",
      promotion:
        "Applicable with 5% to 20% increment per year or candidate/s will be shifted to Career Progression Programme with no retirement",
      pensionScheme:
        "Available up to 75 years of Age, Retirement – at the age of 65 years",
      mediclaimFacility: "Available for 2 Adult + 1 Child",
      groupTermInsurance: "Rs. 15,00,000/-",
      ageLimit: "22 to 50 years as on 01-01-2024",
      language:
        "Candidate must have the ability to speak (Read and Write also) fluently in the regional or local language of the region for which he/she opted in the Application Form (consider one state as one region). Mandatory – English (read & write) Hindi (speaks)",
      jobResponsibility:
        "Team management; leadership development for self and for team; helping entire district’s team in Healthcare & Wellness Awareness, Assessment and Healthcare Counselling Programmes; Monitoring daily activities of juniors; allotment and raise requirements of healthcare tools, foods/supplements; communicating with our 2nd party Vendors those are tied-up with us for supplying of various commodities with the help of RWO; have to perform other official duties that will be asked by the reporting RWO and the Mission Director; taking care of or helping the healthcare entrepreneurs who will work on this mission in the concerned District/s. Maintain the financial transaction which will be involved in between NHWM/Freelancer & health-seekers in the field for delivery of food/supplements/healthcare-tools. Updating the health assessment fees, collected by the AWO or DWO himself/herself, daily basis in the system",
      reporting: "Reporting to concerned RWO OR Zonal Head OR Mission Director",
    },
    {
      title: "Area Officer, AWO :- Vacancy - 2500 posts",
      qualifications: "HS Pass or Equivalent",
      preference:
        "1 year & above experience in Healthcare or Wellness Industry/Pharma Industry/Insurance; Candidate/s having good experience in the concerned field will get age relaxation of 5 years",
      serviceType: "Full-time (work from home or office 10:30 AM to 5:30 PM)",
      salary: "Rs. 15,000/- to 30,000/- per month",
      promotion:
        "Applicable with 5% to 20% increment per year or candidate/s will be shifted to Career Progression Programme with no retirement",
      pensionScheme:
        "Available up to 75 years of Age, Retirement – at the age of 65 years",
      mediclaimFacility: "Available for 2 Adult",
      groupTermInsurance: "Rs. 10,00,000/-",
      ageLimit: "20 to 50 years as on 01-01-2024",
      language:
        "Candidate must have the ability to speak (Read and Write also) fluently in the regional or local language of the region for which he/she opted in the Application Form (consider one state as one region). Other language – Hindi (speaks), English (read & write)",
      jobResponsibility:
        "Self leadership development; Arranging or Conducting Healthcare & Wellness Awareness, Assessment and Counselling Programmes with the help of Senior officers along with qualified Health Specialist; taking care of or helping the healthcare entrepreneurs who will work on this mission in the concerned District of the AWO. Maintain the financial transaction which may be involved in between freelancers or health-seekers in the field for delivery of food/supplements/healthcare-tools. Collection of health assessment fees and updating daily basis in the system",
      reporting: "Reporting to mapped DWO OR sometimes upper hierarchy also",
    },
    {
      title: "HR Officer, HRWO :- Vacancy - 10 posts",
      qualifications:
        "BBA Pass or Equivalent  (Human Resource/Sales & Marketing/Operations Management/Leadership Management)",
      preference:
        "MBA (Human Resource/Sales & Marketing/Operations Management/Leadership Management)\n2 years & above experience in respective field",
      serviceType: "Full-time (work from office 10:30 AM to 6 PM)",
      salary: "Rs. 35,000/- to 60,000/- per month + Yearly Bonus",
      promotion: "Applicable with 5% to 20% increment per year",
      pensionScheme:
        "Available up to 75 years of Age, Retirement – at the age of 65 years",
      mediclaimFacility: "Available for 2 Adult + 1 Child",
      groupTermInsurance: "Rs. 15,00,000/-",
      ageLimit: "22 to 45 years as on 01-01-2024",
      language:
        "Mandatory – English (read, write & speaks); Hindi (speaks)\nOptional – Assamese (speaks) or Bengali (speaks)",
      jobResponsibility:
        "HR management, Sales & Marketing Management, Business Analysis, Manpower Hiring Management, Employee Skill Management  Leadership Management, Office Operation Management etc",
      reporting: "Mission Director",
    },
    {
      title: "IT Officer, ITWO : - Vacancy - 10 posts",
      qualifications:
        "B.Tech, BCA Pass or Equivalent (Information Technology and Information Systems/Computer Science/information Science/Software Engineering/Computer Engineering/Cyber Security)",
      preference:
        "2 years & above experience in respective field/software development/website designing and official IT management",
      serviceType: "Full-time (work from office 10:30 AM to 6 PM)",
      salary: "Rs. 35,000/- to 60,000/- per month + Yearly Bonus",
      promotion: "Applicable with 5% to 20% increment per year",
      pensionScheme:
        "Available up to 75 years of Age, Retirement – at the age of 65 years",
      mediclaimFacility: "Available for 2 Adult + 1 Child",
      groupTermInsurance: "Rs. 15,00,000/-",
      ageLimit: "22 to 45 years as on 01-01-2024",
      language:
        "Mandatory – English (speaks, read & write), Other – Hindi (speaks, read & write), Assamese (speaks, read & write), Bengali (speaks, read & write)",
      jobResponsibility:
        "Monitoring all IT system in the organization, Responsible to keep the organization handy in work with web, software and apps development, Security and Governance, Helping the entire employee with support, helping HR dept. with tools and software support",
      reporting: "Mission Director",
    },
  ];
};

export const ACCUSATIONS: string[] = [
  "Obtaining support for candidature by any means",
  "Impersonation",
  "Procuring impersonation by any person",
  "Submitting fabricated & tampered documents",
  "Making statements which are incorrect or false or suppressing material fact or information",
  "Using unfair means during the examination",
  "Writing irrelevant or obscene or pornographic matter in the script/s or uploaded in the online form",
  "Misbehaving online or offline with the examiner or the invigilator",
  "Harassing or causing bodily harm to the staff employed/engaged by the board",
  "Violating any of the instructions contained in the Exam Admit Card",
  "Attempting to commit or abetting any of the acts specified in the clauses",
  "Violating any of the Terms, Conditions or Rules",
  // Add more accusations as needed
];

export const CONSEQUENCES: string[] = [
  "To be disqualified by the Board from the examination",
  "To be suspended, either permanently or for a specified period, from appearing in any examination or selection held by the Board",
  "If already in service under government/private and did not declare in the application form, candidature will be suspended either permanently or for a specific period",
  "To face legal events/actions as decided to sue by the Management of NHWM",
];

export const Procedures = [
  {
    title: "Selection Procedure",
    descriptionList: [
      "Candidate must fill-up the appropriate Online Application Form of Basic Recruitment Test. Candidate must appear in the Basic Recruitment Test for which post he/she want to apply.",
      "Candidate for DWO & AWO must be appeared in the Test of one paper of 100 marks with two parts i.e. Part 1- General Knowledge of 20 marks and Part 2- Special Paper of 80 marks as will be mentioned in the Syllabus (MCQ, Short Answer and Descriptive Written).",
      "Candidate for HRWO must be appeared in the Test of one paper of 100 marks with two parts i.e. Part 1- General Knowledge of 20 marks and Part 2- Special Paper of 80 marks according to the option selected in the Application Form and as will be mentioned in the Syllabus (MCQ, Short Answer and Descriptive Written).",
      "Candidate for ITWO must be appeared in Test of one paper of 100 marks with two parts i.e. Part 1- General Knowledge of 20 marks and Part 2- Special Paper of 80 marks as will be mentioned in the Syllabus (MCQ, Short Answer and Descriptive Written).",
      "Candidate for RWO must be appeared in Test of one paper of 100 marks with two parts i.e. Part 1- General Knowledge of 20 marks and Part 2- Special Paper of 80 marks as will be mentioned in the Syllabus (MCQ, Short Answer and Descriptive Written). ",
      "Qualified Merit Listed candidates will be selected for viva and then for TRAINING. ",
      "Candidates who would be finally selected after evaluating the Training Assignment, will be appointed in concerned post, possibly in his/her preferred Region/District with proper official Appointment Letter.",
    ],
  },
  {
    title: "Test OR Interview Processing Fees",
    descriptionList: [
      "For the post of RWO Rs. 1200/- (Twelve Hundred) per application, (a candidate can apply up to 5 regions according to his/her preference with 5 separate Applications & Fees).",
      "For the post of DWO Rs. 900/- (Nine Hundred) per application, (a candidate can apply up to 5 districts according to his/her preference with 5 separate Applications & Fees).",
      "For the post of AWO Rs. 600/- (Six Hundred) per Application, (a candidate can apply up to 5 districts according to his/her preference with 5 separate Applications & Fees).",
      "For the post of HRWO & ITWO Rs. 900/- (Nine Hundred) per Application, (restricted to one application only in these Posts per candidate, however such candidate/s can apply for other posts with separate Applications & Fees).",
    ],
  },
  {
    title: "Rules, Terms & Conditions",
    descriptionList: [
      "Government or Private Servants, whether temporary or permanent, are eligible to appear at the examination provided they possess the requisite qualifications and are within the prescribed age limit. They may produce experience certificate from their employer.",
      "No application shall be considered, unless it is accompanied by proper proof of payment of exam fees as mentioned in the advertisement.",
      "The important dates might be changed which will be notified in the website of NHWM and Optimal Health, all rights are reserved by Mission Director, NHWM.",
      "No claim for refund of the fees shall in any circumstances be entertained.",
      "A candidate must be of good mental condition and bodily healthy and free from any physical defect likely to interfere with the discharge of his duties as an officer of the service. A candidate, who after medical examination as the selection board may prescribe, is not found to satisfy these requirements will not be appointed.",
      "Candidates shall upload one copy of their recent passport size coloured photographs with online application form.",
      "Any attempt, on the part of candidate to obtain support for his candidature, by any means, may be held by the Selection board to disqualify him for admission to the examination at any stage.",
      "No person who has more than one spouse living or in case of woman candidate if married to a person having a spouse living shall be eligible for appearing at the examination. Provided, if satisfied that such marriage is permissible under the personal law applicable to such person, exempt from the operation of this rule.",
      "The decision of the Examination Board and Selection Board as to the eligibility of a candidate for admission to the examination or selection for the training and appointment shall be final and no candidate to whom a certificate of admission has not been issued by the board/s shall be admitted to the examination/training.",
      "The application of a candidate shall be summarily rejected if the same is not complete in all respects as provided in the rules, regulations, application form and supplementary instructions to the candidates and is not received by the last date notified for the receipt of application.",
      "On the basis of the results of the Competitive Recruitment Examination and tests conducted by the Examination Board of NHWM shall prepare a list of successful candidates found suitable for Training in order of merit. This list shall also be published by the Board in the concerned website of NHWM and will be informed the candidate/s in the possible ways.",
      "The candidate/s, who secures minimum qualifying marks in the Exam, as fixed by the Examination Board, shall be called to Viva & Physical test and will be selected for training. Original documents must be shown by the candidate in front of Interview Board at the time of Viva.",
      "Candidates which are selected for training must pay a Training Processing Fees which will be communicated after publication of selection list.",
      "Selection of candidate for training does not mean the final selection for appointment.",
      "The final list of candidate/s selected for appointment will be prepared after the completion of training and evaluation of training assignment.",
      "The dropped out candidates after training will get relaxation of 10 marks in the next set of Competitive Recruitment Examination and 15 mark in the next training assignment along with 5 years of age relaxation during coming 5 years from the date of declaration of final selection list of 2023.",
      "If the number of candidates in the final selection list will exceed, the number of vacant posts available, the appointment will be done on merit basis up to the fill up of vacant post. Other candidate/s that will not get appointed will be kept on Queue in a waiting list for one year (365 days) from the date of declaration of final selection list and will be called personally for next batch of appointment. However, such candidate can join in any other organization during this period.",
      "If any post will not be vacated during waiting period (365 Days) or new post would not be created by the management of NHWM, Queued candidate will get relaxation of 15 marks in the next set of Competitive Recruitment Examination and 20 mark in the training assignment along with 5 years of age relaxation during coming 5 years from the date of declaration of final selection list of 2023. Such candidates if selected again need not to pay any training processing fees during coming 3 years from the date of declaration of final selection list of 2023.",
      "No accommodation, remuneration and traveling cost will be provided by the management of NHWM to the candidate/s invited for viva or Physical Test.",
      "Candidates securing the highest places on the results of the Examination & Training Assignment shall subject to the order of preference that has been expressed by them in their applications, be allotted to those posts up to the number of vacancies that are decided to be filled up by the Management. Due consideration shall be given to the preference exercised by a candidate at the time of his application for admission to the examination.",
      "Candidates shall be considered for appointment to Services/Location subject to availability of vacancies in that Job/Location.",
      "In cases where the preferences given by candidates become unworkable either due to the number of candidates giving preference for a particular job/location being more than one number of vacancies or vice versa, Board of Selection of NHWM shall have the right to allot a candidate to any service/location for which he/she is consider most suitable irrespective of any preference given by her/him, in making such allotment, Board shall have regard as far as possible to the professional qualification and background that the candidate possesses.",
      // "A candidate who is or has been declared by the Board to be guilty of obtaining support for his candidature by any means, impersonation, procuring impersonation by any person, submitting fabricated & tampered document, making statements which are incorrect or false or suppressing material fact of information, resorting to any other irregular or improper means in connection with his candidature for the examination, using unfair means during the examination, writing irrelevant matter including obscene language or pornographic matter in the script/s or uploaded in the online form, misbehaving online or offline with the examiner or the invigilator in any manner before, in between and after the examination/training, harassing or causing bodily harm to the staff employed/engaged by the board for the conduct of examination/training, violating any of the instructions contained in the Exam Admit Card, attempting to commit or as the case may be, abetting the Board of all or any of the acts specified in the forgoing clauses may be liable to be disqualified by the Board from the examination for which he is a candidate; to be suspended, either permanently or for a specified period by the board, from appearing in any examination or selection held by the Board; if he/she is already in service under government/private and did not declared in application form, his/her candidature will be suspended either permanently or for a specific period which will be decided by the Board; to be faced legal events/action as decided to sue by the Management of NHWM",
      "None, be it a candidate of examination; selected or non-selected; appeared or non appeared candidate in the examination/training or any employee new or existing of NHWM can approach to any third party organization/person be it Government or Non-Government for raising any dispute regarding anything against NHWM or against our collaborative partner ‘Optim Health & Wellness (OPC) Pvt. Ltd.’, unless and until Management of NHWM and Optim Health & Wellness (OPC) Pvt. Ltd. will deny in written to resolve the dispute internally.",
      "None, be it a candidate of examination; selected or non-selected; appeared or non appeared candidate in the examination/training or any employee new or existing of NHWM can involve in any union of any form or can involve in creation of union of any form.",
      "Board of Examination, the management of NHWM and our collaborative partner ‘Optim Health & Wellness (OPC) Pvt. Ltd.’ reserve the right to change, cancel, alter, decrease or increase the number of post, decrease or increase the salary fixed in this Advertisement according to the grade of post, postpone or terminate anything regarding this Advertisement before, during & after the examination, selection and appointment at anytime without prior notice to anybody inside or outside of NHWM.",
    ],
  },
];
export const FAQS: FAQ[] = [
  {
    question: "Is it a fraud Advertisement?",
    answer:
      "Absolutely NOT. This is not a fraud. Before releasing this advertisement we have completed all the formalities required. The NHWM is launched for a very genuine purpose to educate and help people of Bharat. We have intimated about our mission to PM of Bharat, CM of every State of Bharat and also intimated to the Office of the Director of Health Services of every state. (Candidates are Requested to beware of Fraud Job Advertisements).",
  },
  {
    question: "What is NHWM?",
    answer:
      "National Healthcare and Wellness Mission 2023 is a mission nationwide in Bharat for educating people how to stay healthy & get prevented or reverse from Lifestyle Diseases (like Heart Disease, Diabetes & many other Degenerative Diseases) and how to save ourselves from heavy healthcare costing that may come unexpectedly in someone’s life. This mission will be associating with awareness presentation in English, Hindi and regional language along with proper expertised health assessment and counselling programme in a very nominal cost.",
  },
  {
    question:
      "Is this advertisement for any central or any state government job?",
    answer:
      "NHWM is a collaborative mission. Though, it is not a government initiative, NHWM provides as much facilities as possible to their employee like a government employee gets. So, we adopt all the employee-friendly policies which are attracting good quality people to work with NHWM. So, people are crazy to join as an employee in NHWM.",
  },
  {
    question: "What are the mission phases of NHWM?",
    answer:
      "NHWM has many phases of working Strategy. The first phase is for 7 years starting from 1st of January 2024 to 31st of December 2030. The other phases will be of 5 years each with proper strategies. Total 5 phases and working strategies have been formulated up to 31st of December 2050.",
  },
  {
    question: "How many job posts are vacant as of now for NHWM?",
    answer:
      "For the time being, 3200 posts of various responsibilities are vacant nationwide, and vacant posts are segregated region-wise (one or more than one states is representing a region).",
  },
  {
    question: "Will any doctors be involved in this mission?",
    answer:
      "Yes, we already have few qualified doctors. But, we need more, so, qualified doctors are most welcome to apply for the Competitive Recruitment Examination in the post of Regional Officer (RWO).",
  },
  {
    question: "Where is the head office of NHWM situated?",
    answer:
      "For the time being, it is situated in 16, RK Kakati Path, Dr Zakir Hussain Road near Nabajyoti Club, Hengrabari, Guwahati, Assam, Bharat - 781036.",
  },
  {
    question:
      "Why should I appear in the Basic Recruitment Test to get a job in NHWM?",
    answer:
      "To be successful in any Good Purpose or Mission there should be associated a right set of working people. To select a right set of people in NHWM we prefer this type of Exam, and everyone who wants to be a part of this mission should prove himself/herself as a right candidate for filling up the vacant posts.",
  },
  {
    question: "Why do I have to pay an Examination Fees?",
    answer:
      "Examination Fees are required to help the NHWM meet the big financial liabilities involved in the Competitive Recruitment Examination for selecting the right candidate/s for a big number of vacant posts of NHWM. So, who want to apply will pay the appropriate Examination & Interview Processing Fees.",
  },
  {
    question: "I am not a citizen of Bharat. Can I apply?",
    answer: "No, you are not eligible to apply for this Exam.",
  },
  {
    question:
      "I will be obtaining my Bachelor or Master degree in June of next year (2024). Can I apply?",
    answer:
      "Please check the age limit and the qualification required for each category of vacant posts. If your situation is matching the requirements then you can apply.",
  },
  {
    question:
      "What kind of reference Study Materials could I use to prepare for the examination?",
    answer:
      "Candidates can prepare with any General Knowledge book available in the market with special reference to his/her state in which a candidate belongs to. Special paper for DWO is Class IX & X standard General Science + Principles of Human Nutrition. Special paper for AWO is Class VIII & IX Standard General Science + Basics of Human Nutrition. RWO candidates will prepare according to the subjects that will be mentioned in the Syllabus. ITWO candidates will prepare according to the subjects that will be mentioned in the Syllabus. HRWO candidates will prepare according to the subjects that he/she opted at the time of application form submission and according to the subjects that will be mentioned in the Syllabus.",
  },
  {
    question:
      "What is a Career Progression Program mentioned in the Advertisement?",
    answer:
      "It is a lifetime incentive programme. This incentive program will be started simultaneously with the salary from the very 1st month of joining of the employee. When this incentive crosses the 200 % of the Salary continuously for 3 – 6 months, the employee will be shifted to this lifetime incentive programme. This programme will help the employee to grow too many folds than normal salary. The dedication level of the employee will be at utmost level because of this programme, and we will be very happy to see our employee happy.",
  },
];
