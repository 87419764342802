import { RouterHandler } from "./RouterHandler";
import REACTGA from "react-ga4";

REACTGA.initialize("G-8V2VQWGPDL");
function App() {
  return (
    <div>
      <RouterHandler />
    </div>
  );
}

export default App;
