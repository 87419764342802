export const TnC = () => {
  const termsAndConditions = [
    "No Guidebooks will be dispatched, unless it is accompanied by proper proof of payment of the price of the Guidebook as mentioned in the Guidebook ordering page of our recruitment portal.",
    "The important dates might be changed which will be notified in the recruitment portal of NHWM, all rights are reserved by Mission Director, NHWM.",
    "No claim for refund of the price of the Guidebook shall in any circumstances be entertained.",
    "Syllabus of BRT will be inside of Each Guidebook. No separate Syllabus will be supplied to any candidate.",
    "Any person or any candidates would not be allowed to create Xerox copy/Photostat Copy/Photocopy of any content of any Guidebook and no one will be allowed to share or circulate such copies or any content of the Guidebook in any means or in social media or anywhere else. If anyone is found to be doing so, his/her candidature will be terminated permanently and a strict legal action will be started against such candidates/person.",
    "NHWM or its supportive Organization will not be liable, if Guidebook is not delivered in the address of candidates or postal delay of delivery.",
    "NHWM or its supportive Organization will not be liable/responsible, if Guidebook is delivered in the wrong address provided by the candidates.",
    "Guidebooks of BRT will be sent to a candidate only who have already applied for BRT and put proper address in the order form. Guidebook of BRT of NHWM will not be dispatched to any candidate who has not paid the Examination Fee or has not repaid the Refunded Fee.",
    "The order of the Guidebook of a candidate shall be summarily rejected if the same is not complete in all respects as provided in the rules, regulations, application form and supplementary instructions to the candidates and is not received by the last date notified for the receipt of Order.",
    "Guidebooks will be dispatched according to the preferences of States and Date as mentioned in the heading ‘Important Dates’ in the recruitment portal of our website.",
    "Board of Examination, the management of NHWM and our collaborative partner ‘Optim Health & Wellness (OPC) Pvt. Ltd.’ reserve the right to change, cancel, alter, decrease or increase the price of the Guidebook fixed, postpone or terminate anything regarding guidebook at any time without prior notice to the candidate or anyone.",
  ];

  return (
    <div className="flex justify-center items-center flex-col gap-4 pl-9 pr-5 pb-5 navbar-offset">
      <strong className="text-secondary text-2xl md:text-3xl lg:text-4xl font-extrabold mt-10 md:mb-10">
        Terms and Conditions of Guidebook Ordering
      </strong>
      <div className="flex flex-col justify-center">
        <ul className="text-2xl text-left">
          {termsAndConditions.map((term, index) => (
            <li key={index} className="mt-2 list-outside list-disc">

              {term}
              <br />
              <br />
            </li>
          ))}
        </ul>
        <p className="text-medium text-center my-5">
          By Order
          <br />
          Dr. Monideep Boruah
          <br /> Mission Director, NHWM
        </p>
      </div>
    </div>
  );
};
